:root {
    font-size: 62.5%;
}

body {
    font-size: 1.5rem;
}

tr.notbooked {
    &:nth-child(odd) {
        background-color: #f8eeee !important;
    }
    &:nth-child(even) {
        background-color: #f8f6f5 !important;
    }
}
tr.booked {
    &:nth-child(odd) {
        background-color: #eef8ee !important;
    }
    &:nth-child(even) {
        background-color: #f6f8f5 !important;
    }
}
