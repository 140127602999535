.z-5 {
    position: relative;
    z-index: 5;
}

// class to create a full width background on a row
.fullWidth-bg {
    position: relative;
    overflow: hidden;

    &:after {
        position: absolute;
        content: '';
        display: inline-block;
        background-color: $lightgray;
        width: 35%;
        height: 100%;
        bottom: 0;
        right: 0;
        top: 0;
        @include media-breakpoint-down(md) {
            content: unset;
        }

    }
}
