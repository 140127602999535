@mixin row-padding($row-padding-width) {
    margin-right: -$row-padding-width / 2;
    margin-left: -$row-padding-width / 2;
    > .col, > [class*="col-"] {
        padding-right: $row-padding-width / 2;
        padding-left: $row-padding-width / 2;
    }
}
@each $size, $length in $spacers {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            .row-padding#{$infix}-#{$size} {
                @include row-padding($length);
            }
        }
    }
}
