#image_upload {
    label {
        display: block;
    }
    .image-blocks {
        overflow: hidden;
        margin-bottom: 20px;
        display: block;

        label {
            margin-bottom: 0;
        }

        .image-block-container {
            float: left;
            position: relative;
        }

        a:hover {
            outline: none;
            text-decoration: none;
            color: #72afd2;
        }

        .delete-wrapper {
            margin-top: 5px;
            text-align: center;
            .delete-image {
                cursor: pointer;
                &:hover {
                    color: $green;
                }
            }
        }

         .image-block-a:hover .image-block .image-overlay {
            opacity: 1;
        }

        .image-block {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border: 1px solid $gray;

            &.image-block-add {
                text-align: center;
                background-color: white;
                width: 15em;
                height: 15em;
                @media (min-width: 1400px) {
                    width: 18em;
                    height: 18em;
                }
                i {
                    margin-top: 0.7em;
                    @media (min-width: 1400px) {
                        margin-top: 1.1em;
                    }
                    line-height: 150px;
                    font-size: 50px;
                    color: $gray !important;
                }
            }
        }

        .image-block .image-overlay {
            width: 15em;
            height: 15em;
            @media (min-width: 1400px) {
                width: 18em;
                height: 18em;
            }
            background-color: rgba($green, .9);
            text-align: center;
            opacity: 0;

            i {
                margin-top: 0.7em;
                @media (min-width: 1400px) {
                    margin-top: 1.1em;
                }
                line-height: 150px;
                font-size: 50px;
                color: #fff;
            }
        }
    }
}

.modal {

    .tab {
        .nav {
            .nav-item {
                &.active {
                    background-color: $green;
                    border-radius: 0.25rem;
                    a {
                        color: white;
                    }
                }
            }
        }

        .tab-content {
            margin-top: 1em;
        }

        #image_upload_form {
            #dropzone-form {
                height: calc(100vh - 250px);
                border-radius: 0.25rem;
                .dz-message {
                    margin-top: 8em;
                }
                @media (min-width: 1400px) {
                    height: calc(100vh - 425px);
                    border-radius: 0.25rem;
                    .dz-message {
                        margin-top: 13.5em;
                    }
                }
            }
        }

        .image-modal-select #dropzone-form {
            height: calc(100vh - 325px);
        }


        #media_library {

            .image-selection {
                height: calc(100vh - 325px);
                overflow-y: scroll;
            }

            .file-search {
                //display: -webkit-box;
                //display: -ms-flexbox;
                //display: flex;
                //-webkit-box-pack: start;
                //-ms-flex-pack: start;
                //justify-content: flex-start;
                margin-bottom: 15px;
                padding: 0 10px;

                input {
                    width: 300px;
                }

                //.form-control:not(select) {
                //    -webkit-appearance: none;
                //    -moz-appearance: none;
                //    appearance: none;
                //}
            }


            form {
                input[type=radio] {
                    display: none;
                }

                input[type=radio]:focus {
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: -2px;
                }
                 input[type=radio] {
                    margin: 4px 0 0;
                    margin-top: 1px\9;
                    line-height: normal;
                }
                input[type=radio] {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding: 0;
                }

                 label {
                    padding: 10px;
                    display: inline-block;
                    position: relative;
                    cursor: pointer;

                     img {
                         @media (min-width: 1400px) {
                             height: 15em;
                             width: 15em;
                         }
                         height: 12em;
                         width: 12em;
                         -webkit-transition-duration: .2s;
                         transition-duration: .2s;
                         -webkit-transform-origin: 50% 50%;
                         transform-origin: 50% 50%;
                     }
                }

                :checked+label:before {
                    content: "\F00C";
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }

                 :checked+label img {
                    -webkit-transform: scale(.9);
                    transform: scale(.9);
                }

                 label:before {
                    background-color: $green;
                    color: #fff;
                    content: " ";
                    display: block;
                    border-radius: 50%;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    -webkit-transition-duration: .4s;
                    transition-duration: .4s;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                    font-family: 'Font Awesome 5 Free';
                    line-height: 40px;
                    font-size: 24px;
                    z-index: 10;
                }
            }

            //#image-blocks {
            //    display: block;
            //}


        }

    }

}
