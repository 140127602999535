$text-font: 'sofia-pro', sans-serif;
$title-font: 'sofia-pro', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    font-family: $title-font;
}

p {
    font-family: $text-font;
}

h1 {
    @include font-size(5rem);
}

h2 {
    @include font-size(3.5rem);
}

h3 {
    @include font-size(3.5rem);
}

h4 {
    @include font-size(2rem);
}
