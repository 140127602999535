.btn {
    color: white;

    &.ghost {
        color: $red;
        background-color: white;
        border: 1px solid $red;

        &:hover {
            color: white;
            background-color: $red;

        }
    }
}


.btn-link {
    text-decoration: underline;
}

