.form-label-group {
    position: relative;
    margin-bottom: 10px;
}

.form-label-group > input,
.form-label-group > textarea,
.form-label-group > label {
    height: 50px;
    padding: 12px;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all .1s ease-in-out;
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
}

.form-label-group input::-webkit-input-placeholder,
.form-label-group textarea::-webkit-input-placeholder{
    color: transparent;
}

.form-label-group input:-ms-input-placeholder,
.form-label-group textarea:-ms-input-placeholder{
    color: transparent;
}

.form-label-group input::-ms-input-placeholder,
.form-label-group textarea::-ms-input-placeholder{
    color: transparent;
}

.form-label-group input::-moz-placeholder,
.form-label-group textarea::-moz-placeholder{
    color: transparent;
}

.form-label-group input::placeholder,
.form-label-group textarea::placeholder{
    color: transparent;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown){
    padding-top: 20px;
    padding-bottom: 4px;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label{
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
    .form-label-group > label {
        display: none;
    }
    .form-label-group input::-ms-input-placeholder,
    .form-label-group textarea::-ms-input-placeholder{
        color: #777;
    }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group > label {
        display: none;
    }
    .form-label-group input:-ms-input-placeholder,
    .form-label-group textarea:-ms-input-placeholder{
        color: #777;
    }
}
