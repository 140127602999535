.files_list {
    p {
        i {
            color: $red;
            margin-left: 1em;
            cursor: pointer;
        }
    }
}

.btn_file_group {
    .new_Btn {
        // your css propterties
        background-color: $gray !important;
        color: white;
        //padding: 0;
        padding: 1em 4em 0 4em !important;
        i {
            margin-right: 1em;
        }
        margin: 0 !important;
        margin-bottom: 0.15em !important;
    }

    .html_btn {
        display:none;
    }
}

#link_addButton {
    p {
        color: $green;
        text-decoration: underline;
        cursor: pointer;
    }
}

//// button part-create page for file
//.new_Btn {
//    // your css propterties
//    background-color: $gray;
//    color: white;
//    //margin: 0;
//    //margin-bottom: 1em;
//}
//
//.html_btn {
//    display:none;
//}
//
//
//
//button.button-file {
//    background-color: $gray;
//    width: 50%;
//    color: white;
//    margin: 0;
//    margin-bottom: 1em;
//
//    i {
//        margin-right: 1em;
//    }
//}
