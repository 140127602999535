//admin navbar top and side
.navbar {
    font-family: 'filson-pro';
    background-color: white;
    height: 80px;

    .nav-item {
        a {
            img {
                width: 44px;
                height: 44px;
                vertical-align: auto;
                border: 1px solid $gray;
                margin-top: -0.65em;
            }
        }
    }

    .dropdown {
        i{
            margin-left: 0.85em;
            margin-right: 1em;
        }

        span {
            margin-left: 0.50em;
        }

        .nav-link{
            height: auto;
        }
    }

    .add{
        a {
            i {
                margin-right: 2em;
                background-color: $green;
                color: white;
                padding: 10px;
                border-radius: 5px;
                margin-top: -1em;
            }
        }


        .dropdown-menu {
            margin-top: 10px;
            margin-right: 2.5em;
            background-color: $green;
            border: none;
            .dropdown-item {
                color: white;

                &:hover {
                    background-color: $green;
                    text-decoration: underline;
                }
            }
        }

    }

    .dropdown-menu-sm-left {
        .dropdown-item.active,
        .dropdown-item:active {
            color: #fff;
            text-decoration: none;
            background-color: $green !important;
        }
    }
}


.main-sidebar {
    font-family: sofia-pro;
    background-color: white !important;
    border-right: 1px solid $lightgray;
    .brand-link {
        margin-bottom: 30px;
        border:none;
        .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            width: 65%;
        }

    }

    .nav {
        background-color: white !important;



        .nav-item {
            border-left: 4px solid white;
            margin-bottom: 0.70em;

            &:hover, &.active {
                border-left-color: $green;
                .nav-link {
                    background-color: white !important;
                }

                p {
                    color: $green !important;
                }
                .a {
                    stroke: $green;
                }
            }

            .not-active {
                p {
                    color: rgb(129, 129, 129) !important;
                }
            }

            .nav-link {
                margin-left: 30px;
                p {
                    letter-spacing: 0;
                    color: rgb(129, 129, 129);
                    font: Medium 17px/20px sofia-pro;
                    margin-left: 1rem;
                }

                svg {
                    height: 18.5px;
                    width: 18.5px;
                }

            }
        }

        .has-treeview {
            .nav-link.active {
                box-shadow: none;
            }

            .nav-treeview {
                .nav-item {
                    border-color: white;
                }

                margin-left: 1.3em;
            }
        }
    }
}

//login

.login {

    h3 {
        font: Bold 35px/67px sofia-pro;
        margin-bottom: 40px;
        margin-left: -13px;
        color: #464646;
    }


    @media (min-width: 768px) {
        .h-md-100 { height: 100vh; }
    }

    .bg-logo {
        background: $login-bg;
    }

    .btn {
        background-color: $green;
        color: white;
        margin-top: 20px;
        padding: 19px 55px 19px 55px;
        font: Bold 15px/12px sofia-pro;
    }

    img{
        width: 216px;
        height: 114px;
    }


}

//content

.content-wrapper{
    background-color: #F5F6F8 !important;
    .container-fluid {
        margin-top: 35px;
        margin-left: 40px;
        width: 95%;
        h3 {
            font: Bold 20px/67px sofia-pro;
            color: #464646;

            .a {
                stroke: currentColor;
            }
            svg {
                width:25px;
                height:25px;
            }

            span{
                margin-left: 1.5em;
            }
        }

        .quotation_number {
            margin-top: -1.75em;
        }

        .quotation_edit_btn {
            border: none;
            background-color: transparent;
        }

        #delete_quotation {
            color: $green;
            text-decoration: underline;
            cursor: pointer ;
        }

        .btn {
            background-color: $green;
            color: white;
            margin-top: 20px;
            padding: 19px 30px 19px 30px;
            font: Bold 15px/12px sofia-pro;
        }

        .no-underline {
            a {
                text-decoration: none;
            }
        }

        a {
            text-decoration: underline;
            color: $green;
        }

        .form-control-select {
            height: calc(1.5em + 1.7rem + 2px);
            width: 100%;
        }

        //.form-label-group {
        //    width: 80%;
        //}

        .card {
            padding: 2em;
            .card-body {
                padding-top: 0;


                .table {
                    th {
                        font-family: sofia-pro;
                        font-weight: bold;
                        font-size: 17px/20px;
                        color: #464646;
                        background-color: white !important;
                    }

                    a {
                        color: #464646;
                    }

                    a.link_green {
                        color: $green;
                    }

                    .fa-eye {
                        color: #464646 !important;
                    }

                    .status {
                        color: white;
                        padding: 2px 8px 2px 8px;
                        border-radius: 5px;
                        display: inline-block;
                        text-decoration: none;
                        margin-bottom: 0 !important;
                        &.approved {
                            background-color: $green;
                        }

                        &.suggested {
                            background-color: $orange;
                        }

                        &.refused {
                            background-color: #CCCCCC;
                        }

                        //margin-top: 0.5em;
                    }

                    p.link {
                        display: inline;
                        cursor: pointer;
                    }


                    tr:nth-child(even) {
                        background-color: #FFFFFF;
                    }
                    tr:nth-child(odd) {
                        background-color: rgb(245,246,248, 1);
                    }

                    td {

                        .fa-edit {
                            color: $orange;
                        }

                        .fa-trash-alt {
                            margin-left: 1em;
                            color: #DD0000;
                        }

                        .fa-clone {
                            margin-left: 1em;
                            color: #2966A2 !important;
                        }
                    }

                }

                .pagination {

                    justify-content: flex-start;
                    position: absolute;
                    bottom: -68px;
                    left: -14px;
                    margin-top: 25px;
                    margin-bottom: 20px;
                    li{
                        margin-left: .5em;

                        a{
                            text-decoration: none;
                            color: #464646;
                            font-family: sofia-pro;
                            border: 1px solid lighten(#CCCCCC, 10%);
                            border-radius: 7px;
                        }
                        &.active {
                            .page-link {
                                background-color: transparent !important;
                                border: none;
                            }
                        }
                    }
                }

                label.error {
                    color: $red;
                    margin-left: 0.5em;
                    margin-bottom:0.5em;

                }

                .address{
                    p {
                        margin: 0;
                        padding: 0;
                    }

                    p:last-of-type {
                        margin-bottom: 1em;
                    }

                }
            }


            .card-header {
                background-color: white !important;
                padding-bottom: 0;
            }

        }

        .progress-bar {
            background-color: $green;
        }

        .heading {
            .btn {
                margin-left: 1.05em;
                font-weight: lighter;
            }

            .row {
                margin-bottom: 1em;
            }

        }

        .disapproved {
            color: #464646 !important;
        }

        .approved {
            a {
                text-decoration: none;
            }

            .card {
                padding: 0;
                border: 1px solid $lightgray;
                border-left: 6px solid $green;
                border-radius: 0px;
                margin-right: 1em;
                margin-bottom: 1em;

                .card-body {
                    margin-top: 1.7em;
                }

                i {
                    color: $green;
                }
                .col {
                    color: #464646 !important;
                    text-decoration: none !important;
                }
                span {
                    font: Regular 15px/22px sofia-pro;
                    color: #818181;
                }

                background: linear-gradient(to right, $green 50%, white 50%);
                background-size: 200% 100%;
                background-position: right bottom;
                transition: all .25s ease-out;
                &:hover {
                    color: white;
                    background-position: left bottom;
                    background-color: $green;

                    i, .col {
                        color: white !important;
                        text-decoration: none;
                    }

                    span {
                        color: white;
                    }
                }
            }
        }


        .card {
            .card-body {
                ul {
                    .list-group-item {
                        border-bottom: 1px solid $lightgray;
                    }
                    .overview {

                        a {
                            text-decoration: none;
                            color: #464646;
                        }
                        span {
                            font: Regular 15px/22px sofia-pro;
                            color: #818181;
                        }

                        .fa-edit {
                            color: $orange;
                        }

                        .fa-trash-alt {
                            margin-left: 1em;
                            color: #DD0000;
                        }
                    }
                }
            }

            h5 {
                margin-top: 1em;

                &.price {
                    font-weight: bolder;
                }
            }
        }




        .suggested {

            a {
                text-decoration: none;
            }


            .card {
                padding: 0;
                border: 1px solid $lightgray;
                border-left: 6px solid $orange;
                border-radius: 0px;
                margin-right: 1em;
                margin-bottom: 1em;

                .card-body {
                    margin-top: 1.7em;
                }
                i {
                    color: $orange;
                }

                .col {
                    color: #464646;
                }

                span {
                    font: Regular 15px/22px sofia-pro;
                    color: #818181;
                }

                background: linear-gradient(to right, $orange 50%, white 50%);
                background-size: 200% 100%;
                background-position: right bottom;
                transition: all .25s ease-out;

                &:hover {
                    color: white;
                    background-position: left bottom;
                    background-color: $orange;

                    i, .col {
                        color: white !important;
                        text-decoration: none;
                    }

                    span {
                        color: white;
                    }

                    .approve, .disapprove {
                        color: white;
                    }
                }

                .links {
                    margin-top: 1em;
                }

                .approve
                {
                    text-decoration: underline;
                }

                .disapprove {
                    text-decoration: underline;
                    color: #818181;
                }
            }
        }


        .product-add {
            button {
                width: 100%;
                background-color: $gray;
                margin: 2em 0 0 0 !important;
            }
        }

        .form-control-fullwidth {
            width: 100%;
        }


        .card {
            .card-header {
                span.fa-stack {
                    margin-top: 1.3em;
                    cursor: pointer;
                    i.fa-circle {
                        color: $green;
                    }
                }

            }
        }

    }
}

//modal
.modal {

    .form-control-select {
        height: calc(1.5em + 1.7rem + 2px);
        width: 100%;
    }
    .modal-body {
        a {
            color: $green;
            text-decoration: none;
            background-color: transparent;
        }
    }

    .modal-footer {
        .btn {
            &.btn-green {
                background-color: $green;
                color: white;
            }

            &.btn-gray {
                background-color: $gray;
                color: white;
            }
        }
    }
}

//input link
#inlineFormInputGroup {
    .input-group-text {
        color: white;
        background-color: $green;
        padding: 1em;
        margin-left: -1em;
        border-radius: 5px;
        border-color: $green;
    }
    a {
        text-decoration: none;
    }

    input.form-control {
        width: 13em;
        @media (min-width: 1620px) {
            width: 16em;
        }
        @media (min-width: 1820px) {
            width: 20em;
        }
        height: 3.1em;
        color: $darkgray;
    }
}

#link_message {
    margin-top: 0.5em;
    color: $darkgray;
    margin-bottom: 0;
}

//#mailtemplates_form {
//    input {
//        width: 100%;
//    }
//
//    textarea {
//        width: 100%;
//    }
//}

#placeholders {
    p {
        font-weight: bolder !important;
    }
}

.part {
    .name {
        text-decoration: underline;
    }

    p.link {
        display: inline;
        cursor: pointer;
        .fa-trash-alt {
            margin-left: 5.5em;
            color: #DD0000;
        }
    }

}


